
import { Component, Prop } from 'vue-property-decorator';
import StackedForm from '../../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { Category } from '@/interfaces/models/Category';
import { Article } from '@/interfaces/models/Article';
import { ArticleRelativeBrand } from '@/enums/ArticleRelativeBrand';

@Component({
  components: { VFormBuilder },
})
export default class GeneralForm extends mixins(StackedForm) {
  @Prop({ type: Array, required: true }) public categories!: Category[];
  @Prop({ type: Array, required: true }) public articles!: Array<Partial<Article>>;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  get items() {
    return [
      { name: 'name', type: InputType.Language, useTextarea: true, label: 'article.form.name', rules: 'required' },
      { name: 'internalName', type: InputType.Textarea, label: 'article.form.internalName' },
      { name: 'description', type: InputType.Language, useTextarea: true, label: 'article.form.description' },
      { name: 'shortDescription', type: InputType.Language, useTextarea: true, label: 'article.form.shortDescription' },
      { name: 'isActive', type: InputType.Checkbox, label: 'article.form.active', default: true },
      { name: 'visible', type: InputType.Checkbox, label: 'article.form.visible', default: true },
      { name: 'number', type: InputType.Text, label: 'article.form.number', rules: 'required' },
      { name: 'priceIsLocked', type: InputType.Checkbox, label: 'article.form.priceIsLocked', default: false },
      {
        name: 'price',
        type: InputType.Price,
        label: 'article.form.price',
        rules: 'required',
        disabled: this.isDisabledCallback,
      },
      {
        name: 'displayPrice',
        type: InputType.Price,
        label: 'article.form.displayPrice',
        default: null,
        disabled: this.isDisabledCallback,
      },
      {
        name: 'mainRecommendations',
        type: InputType.AutocompleteWithDrag,
        label: 'article.form.mainRecommendations',
        multiple: true,
        default: [],
        items: this.articleItems,
        useChips: true,
      },
      {
        name: 'category',
        type: InputType.Select,
        label: 'article.form.category',
        rules: 'required',
        items: this.cats,
      },
      {
        name: 'code',
        type: InputType.Text,
        label: 'article.form.code',
      },
      {
        name: 'taxInside',
        type: InputType.Select,
        label: 'article.form.taxInside',
        rules: 'required',
        items: this.taxes,
        default: '-1',
      },
      {
        name: 'taxTakeaway',
        type: InputType.Select,
        label: 'article.form.taxTakeaway',
        rules: 'required',
        items: this.taxes,
        default: '-1',
      },
      {
        name: 'articleRelativeBrand',
        type: InputType.Select,
        items: this.brands,
        label: 'article.form.articleRelativeBrand',
      },
      {
        name: 'loyaltyId',
        label: 'article.form.loyaltyId',
        type: InputType.Text,
      },
      {
        name: 'requiredLoyaltyPoints',
        label: 'article.form.requiredLoyaltyPoints',
        type: InputType.Text,
        rules: 'integer',
      },
      {
        name: 'availableDateStart',
        type: InputType.DateTime,
        label: 'article.form.availableDateStart',
      },
      {
        name: 'availableDateEnd',
        type: InputType.DateTime,
        label: 'article.form.availableDateEnd',
      },
      {
        name: 'maxItemsPerCart',
        type: InputType.Text,
        rules: 'integer',
        label: 'article.form.maxItemsPerCart',
      },
    ];
  }

  public isDisabledCallback(form: { priceIsLocked: boolean }) {
    return form.priceIsLocked;
  }

  get cats() {
    return this.categories.map((cat: Category) => {
      return {
        value: cat._id,
        text: this.$options.filters!.localized(cat.name),
      };
    });
  }

  get articleItems() {
    return this.articles.map((o) => ({
      value: o._id,
      text: this.$options.filters!.localized(o.name),
    }));
  }

  get taxes() {
    return [
      { value: '0.20', text: '20%' },
      { value: '0.19', text: '19%' },
      { value: '0.16', text: '16%' },
      { value: '0.10', text: '10%' },
      { value: '0.085', text: '8.5%' },
      { value: '0.081', text: '8.1%' },
      { value: '0.07', text: '7%' },
      { value: '0.077', text: '7.7%' },
      { value: '0.055', text: '5.5%' },
      { value: '0.026', text: '2.6%' },
      { value: '0.025', text: '2.5%' },
      { value: '0.021', text: '2.1%' },
      { value: '0', text: '0%' },
      { value: '-1', text: 'default' },
    ];
  }

  get brands() {
    return [
      { value: null, text: 'None' },
      { value: ArticleRelativeBrand.SEGAFREDO, text: 'Segafredo' },
      { value: ArticleRelativeBrand.NORDSEE, text: 'Nordsee' },
      { value: ArticleRelativeBrand.COFFEE_FELLOWS, text: 'Coffee fellows' },
      { value: ArticleRelativeBrand.DALLMAYR, text: 'Dallmayr' },
    ];
  }

  get availableMainRecommendations() {
    return this.articles.filter((article) => {
      // @ts-ignore
      if (this.initialValues.mainRecommendationsFromCategory && article._id) {
        // @ts-ignore
        return this.initialValues.mainRecommendationsFromCategory.includes(article._id);
      } else {
        return false;
      }
    });
  }
}
